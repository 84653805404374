
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'About',
    components: {},

    mounted() {
        console.debug('About::mounted')
    },
    unmounted() {
        console.debug('About::unmounted')
    },
})
